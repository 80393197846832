import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const cmeBrief =
  "<strong>Experience success like never before</strong> with Exams Are Fun, an avant-garde platform meticulously crafted to empower individuals on their journey towards excellence in various examinations and certifications. Focused on delivering nothing short of brilliance, Exams Are Fun provides top-notch mock exams and comprehensive study resources, promising an effective and captivating learning experience across diverse domains. Harnessing cutting-edge technology, our platform offers realistic exam simulations, personalized feedback, and invaluable insights, allowing users to not just test their knowledge but to elevate it, identify areas for enhancement, and bolster their confidence prior to the actual exams.";

const cmeMissionStmt =
  "<strong>Embark on a transformative exam preparation journey</strong> with Exams Are Fun, where our mission is nothing short of revolutionizing the way individuals prepare for exams and certifications. Rooted in unwavering commitment, we provide a user-centric platform that cultivates excellence, efficiency, and triumph. Through groundbreaking mock exams, interactive learning materials, and data-driven insights, we empower learners with the essential tools needed to excel in their chosen fields. At the heart of our mission lies the vision of being the trusted companion on the educational odyssey of every aspiring professional, offering unwavering support, resources, and expertise to help them confidently crack their exams and achieve their loftiest goals.";

const AboutUs = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: "4rem" }}>
      <Typography variant="h4" gutterBottom>
        {"About Us"}
      </Typography>

      <Typography variant="body1" paragraph dangerouslySetInnerHTML={{ __html: cmeBrief }} />

      <Typography variant="body1" paragraph dangerouslySetInnerHTML={{ __html: cmeMissionStmt }} />

      <Typography variant="body1" paragraph>
        {"If you have any questions or feedback, please feel free to contact us. We appreciate your interest in our website and look forward to serving you."}
      </Typography>
    </Container>
  );
};

export default AboutUs;
