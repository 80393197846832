import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
//  Avatar,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
//import { AddShoppingCart } from "@mui/icons-material";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getMyLearningPacks } from "../../services";
import { RingLoadingIcon } from "../common/LoadingIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "scroll",
    padding: theme.spacing(2),
    "&::-webkit-scrollbar": {
      height: "0.5em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[500],
      borderRadius: "1em",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "1em",
    },
  },
  card: {
    minWidth: 200,
    maxWidth: 250,
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    transition: "box-shadow 0.3s",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "100%",
    overflow: "hidden",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    overflowY: "auto",
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

// Check the environment variable to determine the current environment
const isDevelopment = process.env.REACT_APP_ENV === "development";

const MyLearnings = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [packs, setPacks] = useState([]);
  const [trasformedPacks, setTrasformedPacks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  // useEffect(async() => {
  //   const cachedMyLearnings = localStorage.getItem('cachedMyLearnings');
  //   if (cachedMyLearnings) {
  //     //#PROD console.log("CME_Message - Loading data from local cache !! ");
  //     setPacks(JSON.parse(cachedMyLearnings));
  //   } else {
  //     await fetchMyPacks();
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const cachedMyLearnings = localStorage.getItem("cachedMyLearnings ------");
      if (cachedMyLearnings) {
        setTrasformedPacks(JSON.parse(cachedMyLearnings));
      } else {
        await fetchMyPacks();
      }
    };

    fetchData();
  }, []);

  const fetchMyPacks = async () => {
    setIsLoading(true);
    try {
      //#PROD console.log("In MyLearnings Webapp 1.0 ->");
      const response = await getMyLearningPacks();
      //#PROD console.log("In MyLearnings Webapp 1.1 response -->", response);
      const responseData = response.data;
      setPacks(responseData);

      console.log("In MyLearnings Webapp 1.2 responseData -->", responseData);
      if (Array.isArray(responseData)) {
        const transformedArray = responseData.map((item) => ({
          packid: item.packid,
          //          examtime: "parseInt(item.examtime)",
          packdesc: item.packDesc,
          packtitle: item.packTitle,
          //          examtaken: "parseInt(item.examtaken)",
          //          qcount: "parseInt(item.qcount)",
          prating: parseInt(4),
          // firstname: "item.firstname",
          // lastname: "item.lastname",
//          guruname: `{item.firstname} {item.lastname}`,
          avatarUrl: "https://randomuser.me/api/portraits/men/1.jpg",
          noofreviews: 3,
        }));

        //#PROD console.log("transformedArray -->> ", transformedArray);
        
        setTrasformedPacks(transformedArray);

        // Cache packs data
        if (isDevelopment) {
          localStorage.setItem(
            "cachedMyLearnings",
            JSON.stringify(transformedArray)
          );
        }
      } else {
        // Handle no packs available
        setTrasformedPacks([]);
      }
    } catch (err) {
      console.error(err);
      setSnackbarMessage("Failed to fetch Courses");
      setIsSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewSelect = (selectedPack) => {
    console.log("In MyLearnings Webapp 1.4 selectedPack -->", selectedPack);
    console.log("In MyLearnings Webapp 1.3 packs -->", packs);
    // Filter packs to get the pack with the same packid
    const filteredPack = packs.filter((pack) => pack.packid === selectedPack.packid);
    console.log("In MyLearnings Webapp 1.3 filteredPack -->", filteredPack);
    console.log("In MyLearnings Webapp 1.3 filteredPack[0] -->", filteredPack[0]);
   
    navigate("/mycourses", {
      state: {
        selectedPack: filteredPack[0], // Since filter returns an array, we take the first element
      },
    });

  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  
  // const getUserColor = (firstName, lastName) => {
  //   // Generate a consistent color based on the user's first and last name
  //   const fullName = firstName.toLowerCase() + lastName.toLowerCase();
  //   const hash = fullName.split("").reduce((acc, char) => {
  //     return acc + char.charCodeAt(0);
  //   }, 0);
  //   const colors = [
  //     "#F44336",
  //     "#9C27B0",
  //     "#2196F3",
  //     "#FFEB3B",
  //     "#4CAF50",
  //     "#FF5722",
  //   ]; // Define a set of colors
  //   const index = Math.abs(hash % colors.length); // Get the remainder to select a color
  //   return colors[index];
  // };

  return (
    <div className={classes.root}>
      <Typography
        variant="h4"
        align="center"
        sx={{ marginBottom: "20px", fontWeight: "bold" }}
      >
        My Learnings
      </Typography>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {isLoading ? (
        <div>
          <RingLoadingIcon />
        </div>
      ) : (
        <>
          {trasformedPacks.length === 0 ? (
            <Typography variant="h6" component="p">
              Currently, you have not purchased any exams courses. Enhance your
              learning journey by acquiring our courses and unlock the path to
              success.
            </Typography>
          ) : (
            <Grid container>
              {trasformedPacks.map((pack) => (
                <Grid item key={pack.packid}>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <div>
                        <Typography variant="h6" component="h2" gutterBottom>
                          {pack.packtitle}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* <Typography
                            variant="subtitle1"
                            component="p"
                            gutterBottom
                          >
                            by {pack.guruname}
                          </Typography> */}
                          {/* <Avatar
                      src={pack.avatarUrl}
                      alt={pack.guruname}
                      className={classes.avatar}
                    /> */}
                          {/* <Avatar
                            style={{
                              backgroundColor: getUserColor(
                                pack.firstname,
                                pack.lastname
                              ),
                              color: "#fff",
                            }}
                            className={classes.avatar}
                          >
                            {pack.firstname.charAt(0)} {pack.lastname.charAt(0)}
                          </Avatar> */}
                        </div>
                        <Typography
                          variant="body1"
                          component="p"
                          color="textSecondary"
                        >
                          {pack.packdesc}
                        </Typography>
                      </div>
                      {/* <div>
                  <Typography variant="body2">{pack.guruname}</Typography>
                  <Avatar
                    alt={pack.guruname}
                    src={pack.avatarUrl}
                    className={classes.avatar}
                  />
                </div> */}
                      <div>
                        <Rating
                          name={`rating-${pack.packid}`}
                          value={pack.prating}
                          precision={0.5}
                          readOnly
                        />
                        <Typography variant="body2">
                          {`${pack.prating} (${pack.noofreviews} ${
                            pack.noofreviews === 1 ? "rating" : "ratings"
                          })`}
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={() => handleViewSelect(pack)}
                      >
                        View Pack
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default MyLearnings;
