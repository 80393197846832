import React from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Divider,
  Avatar,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ForumIcon from "@mui/icons-material/Forum";
import GradeIcon from "@mui/icons-material/Grade";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const DashboardPage = () => {
  // Dummy data for demonstration purposes
  const upcomingEvents = [
    { title: "JEE Mains", date: "April 4 to April 15, 2024" },
    { title: "VITEEE", date: "19th to 30th April 2024" },
    { title: "SRMJEEE", date: "Phase 1 April 19 to 21, Phase 2 June 21 to 23" },
    { title: "COMEDK", date: "May 12, 2024" },
    { title: "MHCET", date: "April 16 - 30, 2024" },
    { title: "BITSAT", date: "19 May to 26 Jun 2024" },
    { title: "Manipal Entrance Exam", date: "April 16-17, 2024" },
    { title: "JEE Advanced", date: "May 26, 2024" },
  ];

  const recentActivities = [
    { icon: <AssignmentIcon />, text: "Submitted Assignment 1" },
    { icon: <GradeIcon />, text: "Received 95% on Quiz 3" },
    { icon: <ForumIcon />, text: "Participated in Discussion Forum" },
  ];

  const courses = [
    { name: "Mathematics", instructor: "Dr. Smith" },
    { name: "English Literature", instructor: "Prof. Johnson" },
  ];

  // Function to handle logout
  const handleLogout = () => {
    // Implement logout logic here
    alert("Logging out...");
  };

  return (
    <Grid container spacing={2}>
      {/* Left Sidebar */}
      <Grid item xs={12} md={3}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6">Welcome, Student!</Typography>
          {/* Upcoming Events */}
          <Typography variant="subtitle1" mt={2}>
            Upcoming Events
          </Typography>
          <List>
            {upcomingEvents.map((event, index) => (
              <ListItem key={index} disablePadding>
                <ListItemAvatar>
                  <Avatar>
                    <EventIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={event.title}
                  secondary={event.date}
                />
              </ListItem>
            ))}
          </List>
          <Divider />
          {/* Recent Activities */}
          <Typography variant="subtitle1" mt={2}>
            Recent Activities
          </Typography>
          <List>
            {recentActivities.map((activity, index) => (
              <ListItem key={index} disablePadding>
                <ListItemAvatar>
                  <Avatar>{activity.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={activity.text} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      {/* Main Content */}
      <Grid item xs={12} md={9}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" mb={2}>
            Course Overview
          </Typography>
          {/* Course Cards */}
          <Grid container spacing={2}>
            {courses.map((course, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Paper elevation={1} sx={{ p: 2 }}>
                  <Typography variant="subtitle1">{course.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Instructor: {course.instructor}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      {/* Right Sidebar */}
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2 }}>
          {/* Announcements */}
          <Typography variant="h6" mb={2}>
            Announcements
          </Typography>
          <Button startIcon={<AnnouncementIcon />} variant="outlined">
            View All Announcements
          </Button>
          {/* Other Options */}
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={6}>
              <Button
                startIcon={<CalendarTodayIcon />}
                fullWidth
                variant="contained"
                color="primary"
              >
                View Calendar
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                startIcon={<SettingsIcon />}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Settings
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                startIcon={<HelpIcon />}
                fullWidth
                variant="contained"
                color="primary"
              >
                Help & Support
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                startIcon={<FeedbackIcon />}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Feedback
              </Button>
            </Grid>
          </Grid>
          {/* Logout Button */}
          <Tooltip title="Logout" placement="left">
            <IconButton onClick={handleLogout} sx={{ mt: 2 }}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
