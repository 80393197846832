import React, { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Testimonials from "./Testimonials";
// import ExamPaperTiles from "./ExamPaperTiles";
import ExamPackages from "./Packages/ExamPackages";
import imageUrl from "../../../srcimages/welcomepageimage.jpg";
//import ImageSlider from "../components/ImageSlider";

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = useState(null);
  const [badgeContent, setBadgeContent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkUser(); // Check the initial authentication state
    // Subscribe to Hub Auth events
    Hub.listen("auth", handleAuthEvent);

    return () => {
      // Unsubscribe from Hub Auth events
      Hub.remove("auth", handleAuthEvent);
    };
  }, []);

  const handleAuthEvent = ({ payload: { event } }) => {
    if (event === "signIn" || event === "signOut") {
      checkUser();
    }
  };

  const checkUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUser(userData);
    } catch (error) {
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const updateBadgeContent = (count) => {
    setBadgeContent(count);
    if(badgeContent < 0 ){
    console.log(badgeContent);
    }
  };

  const testimonialsData = [
    {
      id: 1,
      quote:
        "The mock exams provided by ExamsAreFun were a game-changer for my preparation. The realistic format and diverse question bank perfectly simulated the actual exam, boosting my confidence and performance. Highly recommended!",
      author: "Yash, Class 12, Pune",
    },
    {
      id: 2,
      quote:
        "I can not thank ExamsAreFun enough for their comprehensive mock exams. The detailed feedback and analysis helped me identify my weak areas and work on them effectively. A must-have tool for any student aiming for success!",
      author: "Saarthak, Class 7, Pune, India",
    },
    {
      id: 3,
      quote:
        "I highly recommend ExamsAreFun to all students gearing up for exams. The mock exams offered were spot-on in mirroring the actual test, and the detailed explanations helps understanding concepts better. It made exam preparation seamless and effective!",
      author: "Navin, IITM, Chicago, USA",
    },
  ];

  //const welcomeText = "A broad selection of exams and mock exams are available to help you prepare for your exams. Whether you're a beginner or an experienced student, ExamsAreFun has everything you need to succeed."

  const welcomeText1 = `
  A broad selection of exams and mock exams are available to help you prepare for your exams. 
  Whether you're a beginner or an experienced student, ExamsAreFun has everything you need to succeed.
`;
  const welcomeText2 = `Join the party and begin your success story today!`;

  const renderCardContent = () => (
    <CardContent sx={{ flex: "1 0 50%" }}>
      <Typography
        variant="h5"
        align="left"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Welcome to Exams Are Fun - Your Ultimate Exam Companion!
      </Typography>
      <Typography variant="body1" align="left" gutterBottom>
        {welcomeText1}
        <br />
        <br />
        {welcomeText2}
      </Typography>
    </CardContent>
  );

  return (
    <Box>
      <Box>
        {/* Card section */}
        <Card sx={{ maxWidth: 1380, margin: "auto", marginTop: 0 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              {!isSmallScreen && renderCardContent()}
              <CardMedia
                component="img"
                height="245"
                image={imageUrl}
                alt="Welcome Image"
                sx={{
                  width: isSmallScreen ? "100%" : 200,
                  flex: "1 0 auto",
                }}
              />
              {isSmallScreen && renderCardContent()}
            </Box>
          </CardContent>
        </Card>
      </Box>
      {/* <ImageSlider /> */}

      <Box>
      {isLoading ? (
      <p>Loading...</p>
    ) : user ? (
      <>
      <Box> 
        <Box sx={{ marginLeft: "5%" }}>
          <Typography
            variant="h5"
            align="left"
            gutterBottom
            sx={{ mt: 2, padding: "2px", fontWeight: "bold" }}
          >
            Here are our top packages for you! 
          </Typography>
          <ExamPackages updateBadgeContent={updateBadgeContent} />
        </Box>
      </Box>
      {/* <Box> 
        <Box sx={{ marginLeft: "5%" }}>
          <Typography
            variant="h5"
            align="left"
            gutterBottom
            sx={{ mt: 2, padding: "2px", fontWeight: "bold" }}
          >
            Here are our top picks for you! 
          </Typography>
          <ExamPaperTiles updateBadgeContent={updateBadgeContent} />
        </Box>
      </Box> */}
      </>
    ) : (
      <Box>
        <Box sx={{ marginLeft: "5%" }}>
          <Typography
            variant="h6"
            align="left"
            gutterBottom
            sx={{ mt: 2, padding: "2px", fontWeight: "bold" }}
          >
            You are not Logged In. Login to see our top picks for you!  
          </Typography>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleLogin} sx={{marginBottom: "10%"}}>
              Login Here
            </Button>
          </Grid>
        </Box>
      </Box>
    )}
      </Box>
      <Box>
              {/* <Testimonials /> */}
              <Testimonials testimonials={testimonialsData} />
            </Box>

    </Box>
  );
};

export default Home;
