import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
//import { List, ListItem, ListItemText } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
//import AccountCircle from "@mui/icons-material/AccountCircle";
//import MailIcon from "@mui/icons-material/Mail";
import Divider from "@mui/material/Divider";
//import NotificationsIcon from "@mui/icons-material/Notifications";
//import MoreIcon from "@mui/icons-material/MoreVert";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
//import "../../views/css/MultiLevelMenu.css";
import ExamsAreFunLogo from "../../../srcimages/examsarefun.png";
import { Auth, Hub } from "aws-amplify";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

// const checkUser = async (setUser) => {
//   try {
//     const user = await Auth.currentAuthenticatedUser();
//     setUser(user);
//     //#PROD console.log( "User belongs to group => ", user.signInUserSession.accessToken.payload["cognito:groups"])
//     //#PROD console.log("user.signInUserSession.accessToken.payload['cognito:groups'].includes('student') -->> ", user.signInUserSession.accessToken.payload["cognito:groups"].includes("student"));
//   } catch (error) {
//     setUser(null);
//   }
// };

export default function HeaderAppBar() {
  const [user, setUser] = useState(null);
  // const [userGroup, setUserGroup] = useState(null);
  // const [isStudent, setIsStudent] = useState(false);
  // const [isTutor, setIsTutor] = useState(false);
  const [userRoles, setUserRoles] = useState("");

  const { items } = useSelector((state) => state.cart);
  //  const { items, totalPrice } = useSelector((state) => state.cart);

  //  const cartItemsCount = useSelector((state) => state.cart.length);

  const navigate = useNavigate();

//  const [profileMenuAnchorEl, setprofileMenuAnchorEl] = React.useState(null);
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = React.useState(null);

//  const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
  const isMainMenuOpen = Boolean(mainMenuAnchorEl);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [searchText, setSearchText] = useState("");
  
  

 

  const handleSearch = () => {
    event.preventDefault(); // Prevent form submission
    navigate("/searchpack", { state: { searchText } });
  };

  useEffect(() => {
    //#PROD console.log("In Header useEffect 1");
    checkUser(); // Check the initial authentication state
    // Subscribe to Hub Auth events
    Hub.listen("auth", handleAuthEvent);
    //#PROD console.log("In Header useEffect 3");
//    setUserRoles(user?.signInUserSession.accessToken.payload["cognito:groups"]);
    return () => {
      // Unsubscribe from Hub Auth events
      Hub.remove("auth", handleAuthEvent);
    };
  }, []);

  const handleAuthEvent = ({ payload: { event } }) => {
    if (event === "signIn" || event === "signOut") {
      checkUser();
    }
  };

  const checkUser = async () => {
    try {
      //#PROD console.log("In Header checkUser 1");
      const userData = await Auth.currentAuthenticatedUser();
      //#PROD console.log("In Header checkUser 2");
      setUser(userData);
      setUserRoles(userData.signInUserSession.accessToken.payload["cognito:groups"]);
      //#PROD console.log("ID Token Payload:", userData.signInUserSession.accessToken.payload);
      //#PROD console.log("User belongs to group => ", userData.signInUserSession.accessToken.payload["cognito:groups"]);
    } catch (error) {
      setUser(null);
      setUserRoles("");
    }
  };

  const handleLogin = () => {
    handleMainMenuClose();
    navigate("/login");
  };

  const handleContactUs = () => {
   // handleProfileMenuClose();
    handleMainMenuClose();
    navigate("/contact");
  };

  const handleLogout = async () => {
    try {
      //#PROD console.log("In handleLogout 1");
      //#PROD console.log("user -->> ", user);
      await Auth.signOut();
      checkUser();
      setUser(null);
      setUserRoles("");
      handleMainMenuClose();
      //#PROD console.log("In handleLogout 2");
      //#PROD console.log("user -->> ", user);

      // Redirect to login page or update user state to show login page
      navigate("/");
    } catch (error) {
      //#PROD console.log("Error signing out: ", error);
    }
  };

  const handleMainMenuOpen = (event) => {
    //#PROD console.log("In handleMainMenuOpen event -> ", event);
    setMainMenuAnchorEl(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    //#PROD console.log("In handleMainMenuClose event -> ", event);
    setMainMenuAnchorEl(null);
  };
  
  function handleHomeClick() {
    handleMainMenuClose();
    navigate("/");
  }

  
  // function handleCatchGame() {
  //   handleMainMenuClose();
  //   navigate("/catchgame");
  // }

  // function handleChessGame() {
  //   handleMainMenuClose();
  //   navigate("/chess");
  // }

  function handleProfileClick() {
    handleMainMenuClose();
    navigate("/profile");
  }

  // function handleMyCourseClick() {
  //   // Handle menu 1 item click event
  //   handleMainMenuClose();
  //   navigate("/mycourses");
  // }

  
  function handleMyLearningsClick() {
    // Handle menu 1 item click event
    handleMainMenuClose();
    navigate("/mylearnings");
  }

  function handleDashhboardClick() {
    // Handle menu 1 item click event
    handleMainMenuClose();
    navigate("/dashboard");
  }

  function handleCreateExam() {
    handleMainMenuClose();
    navigate("/createexam");
  }

  function handleCreatePackage() {
    handleMainMenuClose();
    navigate("/createpackage");
  }

  function handleMyPapers() {
    handleMainMenuClose();
    navigate("/submittedpapers");
  }
  
  function handleMyPacks() {
    handleMainMenuClose();
    navigate("/mypacks");
  }

  const mainMenuId = "primary-menu";
const renderMainMenu = (
  <Menu
    anchorEl={mainMenuAnchorEl}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    id={mainMenuId}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    open={isMainMenuOpen}
    onClose={handleMainMenuClose}
    sx={{
      // Remove default styling
      "& .MuiMenu-paper": {
        boxShadow: "none",
        margin: 0,
        width: "auto",
        borderRadius: 0,
        overflow: "visible",
      },
    }}
  >
    {[
      (user && userRoles && userRoles.includes("tutor")) && [
        <MenuItem key="home" onClick={handleHomeClick}>
          Home
        </MenuItem>,
        <MenuItem key="profile" onClick={handleProfileClick}>
          My Account
        </MenuItem>,
        // <MenuItem key="my-courses" onClick={handleMyCourseClick}>
        //   My Courses
        // </MenuItem>,
        <MenuItem key="my-learnings" onClick={handleMyLearningsClick}>
          My Learnings
        </MenuItem>,
        <MenuItem key="dashboard" onClick={handleDashhboardClick}>
          Dashboard
        </MenuItem>,
        <Divider key="divider1" />,
        <MenuItem key="create-exam" onClick={handleCreateExam}>
          Create Exam
        </MenuItem>,
        <MenuItem key="create-package" onClick={handleCreatePackage}>
        Create Package
      </MenuItem>,
        <MenuItem key="my-papers" onClick={handleMyPapers}>
          My Papers
        </MenuItem>,
        <MenuItem key="my-packs" onClick={handleMyPacks}>
          My Packs
        </MenuItem>,
        <Divider key="divider2" />,
        <MenuItem key="contact-us" onClick={handleContactUs}>
          Contact Us
        </MenuItem>,
        <MenuItem key="logout" onClick={handleLogout}>
          Logout
        </MenuItem>,
      ],
      (user && userRoles && userRoles.includes("admin")) && [
        <MenuItem key="home" onClick={handleHomeClick}>
          Home
        </MenuItem>,
        <MenuItem key="profile" onClick={handleProfileClick}>
          My Account
        </MenuItem>,
        <MenuItem key="create-exam" onClick={handleCreateExam}>
          Create Exam
        </MenuItem>,
        <MenuItem key="create-package" onClick={handleCreatePackage}>
        Create Package
      </MenuItem>,
        <MenuItem key="my-papers" onClick={handleMyPapers}>
          My Papers
        </MenuItem>,
        <MenuItem key="my-packs" onClick={handleMyPacks}>
          My Packs
        </MenuItem>,
        <MenuItem key="admin-specific">
          Admin-specific Menu Item
        </MenuItem>,
        <MenuItem key="contact-us" onClick={handleContactUs}>
          Contact Us
        </MenuItem>,
        <MenuItem key="logout" onClick={handleLogout}>
          Logout
        </MenuItem>,
      ],
      (user && userRoles && !userRoles.includes("tutor") && !userRoles.includes("admin")) && [
          <MenuItem key="home" onClick={handleHomeClick}>
            Home
          </MenuItem>,
          <MenuItem key="profile" onClick={handleProfileClick}>
            My Account
          </MenuItem>,
          // <MenuItem key="my-courses" onClick={handleMyCourseClick}>
          //   My Courses
          // </MenuItem>,
          <MenuItem key="my-learnings" onClick={handleMyLearningsClick}>
            My Learnings
          </MenuItem>,
          <MenuItem key="dashboard" onClick={handleDashhboardClick}>
           Dashboard
         </MenuItem>,          
          <MenuItem key="contact-us" onClick={handleContactUs}>
            Contact Us
          </MenuItem>,
          <MenuItem key="logout" onClick={handleLogout}>
            Logout
          </MenuItem>,
        
      ],
      (user && !userRoles) && [
        
          <MenuItem key="home" onClick={handleHomeClick}>
            Home
          </MenuItem>,
          <MenuItem key="profile" onClick={handleProfileClick}>
            My Account
          </MenuItem>,
          // <MenuItem key="my-courses" onClick={handleMyCourseClick}>
          //   My Courses
          // </MenuItem>,
          <MenuItem key="my-learnings" onClick={handleMyLearningsClick}>
            My Learnings
          </MenuItem>,
          <MenuItem key="dashboard" onClick={handleDashhboardClick}>
            Dashboard
          </MenuItem>,           
          <MenuItem key="contact-us" onClick={handleContactUs}>
            Contact Us
          </MenuItem>,
          <MenuItem key="logout" onClick={handleLogout}>
            Logout
          </MenuItem>,
        
    ],
  
      (!user && !userRoles) && [
        
          <MenuItem key="home" onClick={handleHomeClick}>
            Home
          </MenuItem>,
          // <MenuItem key="catchgame" onClick={handleCatchGame}>
          //   Have Fun
          // </MenuItem>,
          // <MenuItem key="chess" onClick={handleChessGame}>
          //   Chess
          // </MenuItem>,
                  
          <MenuItem key="login" onClick={handleLogin}>
            Login
          </MenuItem>,
        
    ],
    ]}
  </Menu>
);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {isMobileScreen && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open main menu"
              aria-controls={mainMenuId}
              aria-haspopup="true"
              // onClick={handleMainMenuOpen}
              // sx={{ mr: 2 }}
              onClick={(event) => {
                handleMainMenuOpen(event);
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            // sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            sx={{ display: { xs: "flex", md: "flex" }, alignItems: "center" }}
            >
            {/* Home Icon */}
            <Link to="/" style={{ textDecoration: "none", marginRight: "3px" }}>
              <HomeIcon style={{ fontSize: "35px", color: "white" }} />
            </Link>

            <Link to="/" style={{ textDecoration: "none", marginTop: "10px" }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
                sx={{ display: "block" }}
              >
                <img src={ExamsAreFunLogo} alt="ExamsAreFun Logo" />
              </Typography>
            </Link>
          </Box>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </Search>

          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{ display: { xs: "flex", md: "flex" }, alignItems: "center" }}
          >
            {!user && !isMobileScreen && (
              <>
                <Link
                  to="/login"
                  color="inherit"
                  style={{ textDecoration: "none", marginRight: "10px" }}
                >
                  <Button
                    color="inherit"
                    align="center"
                    variant="outlined"
                    size="small"
                    style={{ color: "white", borderColor: "white" }}
                  >
                    Login
                  </Button>
                </Link>

                <Link
                  to="/login"
                  color="inherit"
                  // style={{ textDecoration: "none", marginRight: "10px" }}
                  style={{
                    color: "white",
                    borderColor: "white",
                    marginRight: "10px",
                  }}
                >
                  <Button
                    color="inherit"
                    align="center"
                    variant="outlined"
                    size="small"
                  >
                    Signup
                  </Button>
                </Link>
              </>
            )}
            {/* {isMobileScreen && ( */}
            <IconButton
              component={Link}
              to="/cart"
              size="small"
              aria-label="shopping cart"
              color="inherit"
              style={{
                color: "white",
                borderColor: "white",
                marginRight: "10px",
              }}
            >
              <Badge badgeContent={items.length} color="error">
                <ShoppingCartIcon />
              </Badge>
              {/* <Typography sx={{ ml: 1, maxWidth: "40px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{totalPrice}</Typography> */}
            </IconButton>

            {/* {user && !isMobileScreen && (
              <>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>

                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </>
            )} */}
          </Box>
        </Toolbar>
      </AppBar>
      {renderMainMenu}

      {/* {renderProfileMenu} */}
    </Box>
  );
}

// // Export the checkUser function separately
// export { checkUser };
