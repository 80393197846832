import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
//import { AddShoppingCart } from "@mui/icons-material";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getPackCoursesForUser } from "../../services";
import { RingLoadingIcon } from '../common/LoadingIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "scroll",
    padding: theme.spacing(2),
    "&::-webkit-scrollbar": {
      height: "0.5em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[500],
      borderRadius: "1em",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "1em",
    },
  },
  card: {
    minWidth: 200,
    maxWidth: 250,
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    transition: "box-shadow 0.3s",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "100%",
    overflow: "hidden",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    overflowY: "auto",
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

// Check the environment variable to determine the current environment
const isDevelopment = process.env.REACT_APP_ENV === 'development';

const MyCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [pack, setPack] = useState();

    // Assigning state value to a variable
//    const selectedPack  = location.state && location.state.pack;
    const selectedPack = location.state?.selectedPack || null;
  const [papers, setPapers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

//   useEffect(() => {
//     const cachedMyCourses = localStorage.getItem('cachedMyCourses');
//     if (cachedMyCourses) {
//       //#PROD console.log("CME_Message - Loading data from local cache !! ");
//  //     setPapers(JSON.parse(cachedMyCourses));
//     } else {
//       fetchMyPapers();
//     }
//   }, []);

useEffect(() => {
  if (selectedPack) {
    setPack(selectedPack);
  }
}, [selectedPack]);

  useEffect(() => {
    const fetchData = async () => {
      const cachedMyCourses = localStorage.getItem('cachedMyCourses-----');
      if (cachedMyCourses) {
        setPapers(JSON.parse(cachedMyCourses));
      } else {
        await fetchMyPapers();
      }
    };

    fetchData();
  }, []);



  // useEffect(() => {
  //   if (location.state && location.state.pack) {
  //     setPack(location.state.pack);
  //   }
  // }, [location.state]);

  const fetchMyPapers = async () => {
    setIsLoading(true);
    try {
      console.log("In MyCourses Webapp 1.0 -> selectedPack is -->> ", selectedPack, pack);
      console.log("selectedPack.paperid -->> ", selectedPack.selectedPapers);
      //const paperIDsString = selectedPack.selectedPapers.join('/');
      const paperIDsString = selectedPack.selectedPapers;

      console.log("In MyCourses Webapp 1.0 -> paperIDsString is -->> ", ""+paperIDsString);
      const response = await getPackCoursesForUser(selectedPack.selectedPapers);
      //#PROD console.log("In MyCourses Webapp 1.1 response -->", response);
      const responseData = response.data.enrichedPaperData;
  
      if (Array.isArray(responseData)) {
        const transformedArray = responseData.map((item) => ({
          pid: item.pid,
          examtime: parseInt(item.examtime),
          paperdesc: item.paperdesc,
          papertitle: item.papertitle,
          examtaken: parseInt(item.examtaken),
          qcount: parseInt(item.qcount),
          prating: parseInt(item.rating),
          firstname: item.firstname,
          lastname: item.lastname,
          guruname: `${item.firstname} ${item.lastname}`,
          avatarUrl: 'https://randomuser.me/api/portraits/men/1.jpg',
          noofreviews: 3,
        }));
  
        //#PROD console.log("transformedArray -->> ", transformedArray);
        setPapers(transformedArray);
         
        // Cache papers data
        if (isDevelopment) {
         localStorage.setItem('cachedMyCourses', JSON.stringify(transformedArray));
        }

      } else {
        // Handle no papers available
        setPapers([]);
      }
    } catch (err) {
      console.error(err);
      setSnackbarMessage('Failed to fetch Courses');
      setIsSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTakeExam = (paper) => {
    if (paper.examtaken === 1) {
      // If exam is taken, navigate to reviewexam
//      navigate("/result", { state: { paper } });
      navigate("/reviewans", {
        state: {
          selectedPack,
          paper
        },
      });
    } else {
      // If exam is not taken, navigate to examinstructions
      navigate("/examinstructions", { state: { selectedPack, paper } });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
  };

  // const handleAddToCart = (id) => {
  //   //#PROD console.log(`Added exam paper with id ${id} to cart`);
  // };

  // const getRandomColor = () => {
  //   // Function to generate a random color in hexadecimal format
  //   return "#" + Math.floor(Math.random() * 16777215).toString(16);
  // };
  const getUserColor = (firstName, lastName) => {
    // Generate a consistent color based on the user's first and last name
    const fullName = firstName.toLowerCase() + lastName.toLowerCase();
    const hash = fullName.split('').reduce((acc, char) => {
      return acc + char.charCodeAt(0);
    }, 0);
    const colors = ['#F44336', '#9C27B0', '#2196F3', '#FFEB3B', '#4CAF50', '#FF5722']; // Define a set of colors
    const index = Math.abs(hash % colors.length); // Get the remainder to select a color
    return colors[index];
  };
  
  return (
    <div className={classes.root}>
            <Typography variant="h4" align="center" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
        My Courses
      </Typography>
            <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {isLoading ? (
      <div>
        <RingLoadingIcon />
      </div>
    ) : (
      <>
        {papers.length === 0 ? (
          <Typography variant="h6" component="p">
            Currently, you have not purchased any exams courses. Enhance your learning journey by acquiring our courses and unlock the path to success. 
          </Typography>
        ) : (
      <Grid container>
        {papers.map((paper) => (
          <Grid item key={paper.pid}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <div>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {paper.papertitle}
                  </Typography>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="subtitle1" component="p" gutterBottom>
                      by {paper.guruname}
                    </Typography>
                    {/* <Avatar
                      src={paper.avatarUrl}
                      alt={paper.guruname}
                      className={classes.avatar}
                    /> */}
                                    <Avatar
                  style={{
                    backgroundColor: getUserColor(paper.firstname, paper.lastname),
                    color: "#fff",
                  }}
                  className={classes.avatar}
                >
                  {paper.firstname.charAt(0)} {paper.lastname.charAt(0)}
                </Avatar>
                  </div>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    {paper.paperdesc}
                  </Typography>
                </div>
                {/* <div>
                  <Typography variant="body2">{paper.guruname}</Typography>
                  <Avatar
                    alt={paper.guruname}
                    src={paper.avatarUrl}
                    className={classes.avatar}
                  />
                </div> */}
                <div>
                    <Rating
                      name={`rating-${paper.pid}`}
                      value={paper.prating}
                      precision={0.5}
                      readOnly
                    />
                  <Typography variant="body2">
                    {`${paper.prating} (${paper.noofreviews} ${paper.noofreviews === 1 ? 'rating' : 'ratings'})`}
                  </Typography>
                </div>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={() => handleTakeExam(paper)}
                >
                  {paper.examtaken === 1 ? "Review Exam" : "Take Exam"}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      )}
      </>
    )}
    </div>
  );
};

export default MyCourses;
